
    <ngx-ui-loader></ngx-ui-loader> 
    <mat-toolbar color="accent" id="mat-toolbar" *ngIf="!ocultar">
      <div fxShow="true" fxHide.gt-sm="true">
        <button mat-icon-button (click)="sidenav.toggle()">
            <mat-icon>menu</mat-icon>
          </button>
      </div>
      <a mat-button class="companyName" routerLink="/administracion/pedidos">
        <span style="font-size: 2rem;"><img src="assets\images\logo_icono_light.png" style="height: 45px;
          margin-left: 1rem;" alt=""></span>
      </a>
      <span class="example-spacer"></span>
      <div id="menu" fxShow="true" fxHide.lt-md="true" style="width: 100%;margin-left: 5rem;">
        <a mat-button routerLink="/administracion" id="administracion" *ngIf="!cliente.es_cliente">Marcaciones</a>
        <a mat-button routerLink="/administracion/pedidos" id="pedidos">Pedidos</a>
        <a mat-button routerLink="/administracion/clientes" id="clientes" *ngIf="!cliente.es_cliente"> Clientes </a>
        <a mat-button routerLink="/administracion/platos" id="platos" *ngIf="!cliente.es_cliente">Platos</a>
        <a mat-button routerLink="/administracion/adicionales" id="adicionales" *ngIf="cliente.es_cliente">Platos adicionales</a>
        <a mat-button routerLink="/administracion/empleados" id="empleados" *ngIf="cliente.es_cliente">Empleados</a>
        <a mat-button routerLink="/administracion/reportes" id="reportes"> Reportes </a>
      </div>
      <p class="sugerencia-bubble" (click)="openTemplateSheetMenu()" *ngIf="!cliente.es_cliente">
        <mat-icon matBadge="{{len}}" matBadgeColor="warn">comment</mat-icon>
      </p>
      <div class="account">
        <p style="margin:0;" fxShow="true" fxHide.lt-md="true">
          <button mat-button [matMenuTriggerFor]="menu">
           {{nombre}}
            <mat-icon>account_circle</mat-icon></button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="logout()"> <mat-icon>exit_to_app</mat-icon> Salir</button>
          </mat-menu></p>  
      </div>
    </mat-toolbar>
    <mat-sidenav-container fxFlexFill class="example-container" >
      <mat-sidenav color="primary" #sidenav fxLayout="column" mode="over"  opened="false" fxHide.gt-sm="true" [hidden]="ocultar">
        <div fxLayout="column">
          <a mat-button routerLink="/administracion" *ngIf="!cliente.es_cliente"> Marcaciones </a>
          <a mat-button routerLink="/administracion/pedidos"> Pedidos </a>
          <a mat-button routerLink="/administracion/clientes" *ngIf="!cliente.es_cliente"> Clientes </a>
          <a mat-button routerLink="/administracion/platos" *ngIf="!cliente.es_cliente"> Platos </a>
          <a mat-button routerLink="/administracion/platos-adicionales" id="platos" *ngIf="cliente.es_cliente">Platos adicionales</a>
        </div>
      </mat-sidenav>
      <mat-sidenav-content fxFlexFill>
        <router-outlet></router-outlet>
      </mat-sidenav-content>
    </mat-sidenav-container>
  <ng-template let-bottomSheetRef="bottomSheetRef" #templateBottomSheet>
    <div class="header-bottom">
      <button mat-flat-button (click)="closeTemplateSheetMenu()" style="float: right;"><mat-icon>clear</mat-icon></button>
    </div>
    <div style="padding: 1rem 1rem 5rem 1rem;">
    <p style="font-size: 1.3rem;font-family: 'DMSansBold' !important;"> Comentarios recibidos ...</p>
    <mat-list>
      <div *ngFor="let suge of sugerencias">
        <mat-list-item >  {{suge.descripcion}}</mat-list-item>
        <mat-divider></mat-divider>
      </div>
    </mat-list>
    </div>
  </ng-template>