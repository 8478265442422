import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/*Material*/ 
import {MatTabsModule} from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatTableModule} from '@angular/material/table';
import {MatMenuModule} from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatStepperModule} from '@angular/material/stepper';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { getDutchPaginatorIntl } from '../dutch-paginator-intl';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthInterceptor } from '../utils/auth.interceptor';
import { ErrorInterceptor } from '../utils/auth-error.interceptor';
import { LoaderComponent } from '../components/loader/loader.component';
import { LoaderButtonComponent } from '../components/loader-button/loader-button.component';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDividerModule} from '@angular/material/divider';
import {MatListModule} from '@angular/material/list';
import { HighchartsChartModule } from 'highcharts-angular';
import {MatCardModule} from '@angular/material/card';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/es';
import { QRCodeModule } from 'angularx-qrcode';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import { NgxCaptureModule } from 'ngx-capture';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { VolverButtonComponent } from '../components/volver-button/volver-button.component';
import {MatDialogModule} from '@angular/material/dialog';
import { AngularFileUploaderModule } from "angular-file-uploader";
// Import ngx-barcode module
import { NgxBarcodeModule } from 'ngx-barcode';
registerLocaleData(localeDe);
import {MatBadgeModule} from '@angular/material/badge';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatChipsModule} from '@angular/material/chips';
import {MatRadioModule} from '@angular/material/radio';

@NgModule({
  declarations: [
    LoaderComponent,
    LoaderButtonComponent,
    VolverButtonComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatTabsModule,
    HttpClientModule,
    MatIconModule,
    MatButtonModule,
    MatSidenavModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatTableModule, 
    MatInputModule,
    FlexLayoutModule,
    MatListModule,
    MatDividerModule,
    MatToolbarModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    NgxBarcodeModule,
    HighchartsChartModule,
    MatPaginatorModule,
    MatBadgeModule,
    MatCardModule,
    AngularFileUploaderModule,
    MatBottomSheetModule,
    MatDialogModule,
    NgxSkeletonLoaderModule,
    MatAutocompleteModule,
    MatSnackBarModule,
    NgxCaptureModule,
    QRCodeModule,
    MatStepperModule,
    SatDatepickerModule, 
    MatProgressBarModule,
    SatNativeDateModule,
    NgMultiSelectDropDownModule,
    MatExpansionModule,
    MatChipsModule,
    MatRadioModule
  ],
  exports:[
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatIconModule,
    MatButtonModule,
    MatSidenavModule,
    MatToolbarModule,
    MatCheckboxModule,
    NgxBarcodeModule,
    MatListModule,
    MatFormFieldModule,
    NgxCaptureModule,
    MatTableModule, 
    MatDialogModule,
    MatBottomSheetModule,
    HighchartsChartModule,
    MatInputModule,
    NgxSkeletonLoaderModule,
    MatCardModule,
    QRCodeModule,
    MatSelectModule,
    MatMenuModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    FlexLayoutModule,
    MatDividerModule,
    AngularFileUploaderModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatStepperModule,
    MatSnackBarModule,
    LoaderComponent,
    SatDatepickerModule, 
    SatNativeDateModule,
    LoaderButtonComponent,
    MatBadgeModule,
    VolverButtonComponent,
    NgMultiSelectDropDownModule,
    MatExpansionModule,
    MatChipsModule,
    MatRadioModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "es" },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    { provide: MatPaginatorIntl, useValue: getDutchPaginatorIntl() },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
})
export class ShareModule { }
