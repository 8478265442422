import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxUiLoaderConfig, NgxUiLoaderModule } from 'ngx-ui-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ShareModule } from './share/share.module';
import { ModalConfirmComponent } from './modals/modal-confirm/modal-confirm.component';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: '#437a73',
  bgsOpacity: 0.5,
  bgsPosition: 'bottom-right',
  bgsSize: 60,
  bgsType: 'rectangle-bounce',
  blur: 5,
  delay: 0,
  fastFadeOut: true,
  fgsColor: '#5eaf00',
  fgsPosition: 'center-center',
  fgsSize: 60,
  fgsType: 'rectangle-bounce',
  gap: 24,
  logoPosition: 'center-center',
  logoSize: 120,
  logoUrl: '',
  masterLoaderId: 'rectangle-bounce',
  overlayBorderRadius: '0',
  overlayColor: 'rgba(40, 40, 40, 0.8)',
  pbColor: '#5eaf00',
  pbDirection: 'ltr',
  pbThickness: 3,
  hasProgressBar: true,
  text: '',
  textColor: '#FFFFFF',
  textPosition: 'center-center',
  maxTime: -1,
  minTime: 300
};



@NgModule({
  declarations: [
    AppComponent,
    ModalConfirmComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    ShareModule,
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ], 
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [
    ModalConfirmComponent// <--- Aquí
  ]
})
export class AppModule { }
