import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Servers } from '../../config/api';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
const TOKEN_KEY = 'auth-token';

@Injectable({
  providedIn: 'root'
})
export class PedidosService {
  public url = Servers.FLEX.baseUrl + 'pedido';
  private urlPlatos = Servers.FLEX.baseUrl + 'platos';
  private urlUsuarios = Servers.FLEX.baseUrl + 'usuario';
  constructor(private http: HttpClient) { }

  get(pk): Observable<any> {
    return this.http.get<any[]>(this.urlPlatos + '/asignaciones/' + pk, httpOptions);
  }
  getAsignacionesDiarias(pk, fecha): Observable<any> {
    return this.http.post<any[]>(this.urlPlatos + '/asignaciones/diarias', { "fecha_menu": fecha, "id": pk }, httpOptions);
  }
  post(body): Observable<any> {
    return this.http.post<any[]>(this.url, body, httpOptions);
  }

  validar(body): Observable<any> {
    return this.http.post<any[]>(this.urlUsuarios + "/validar", body, httpOptions);
  }
  validarAdicional(body): Observable<any> {
    return this.http.post<any[]>(this.urlUsuarios + "/validar-adicional", body, httpOptions);
  }
  enviar(body): Observable<any> {
    return this.http.post<any[]>(this.urlUsuarios + "/enviar-sugerencias", body, httpOptions);
  }
  listarSugerencias(): Observable<any> {
    return this.http.get<any[]>(this.urlUsuarios + "/listar-sugerencias", httpOptions);
  }
  listarPendientes(body): Observable<any> {
    return this.http.get<any[]>(this.url + "/pendientes/"+body.cadena, httpOptions);
  }
  cancelarPendientes(body): Observable<any> {
    return this.http.put<any[]>(this.url + "/pendientes/cancelar",body, httpOptions);
  }
  descargar(): Observable<any> {
    return this.http.get<any[]>(this.url + "/descargar", httpOptionPDF);
  }
}

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

const httpOptionPDF = {
  headers: new HttpHeaders({ 'Content-type': 'application/pdf' })
}