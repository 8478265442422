import { Injectable } from '@angular/core';

export const TOKEN_KEY = 'auth-tokenFlex';
export const USER_KEY = 'auth-userFlex';
export const USER_ATRIBUTOS = 'auth-attr';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  constructor() { }

  signOut() {
    window.localStorage.clear();
  }

  public saveToken(token: string) {
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string {
    return localStorage.getItem(TOKEN_KEY);
  }

  public saveUser(user) {
    window.localStorage.removeItem(USER_KEY);
    window.localStorage.setItem(USER_KEY, JSON.stringify(user));
  }
  public saveAtributos(data) {
    window.localStorage.removeItem(USER_ATRIBUTOS);
    window.localStorage.setItem(USER_ATRIBUTOS, JSON.stringify(data));
  }
  public getUser() {
    return JSON.parse(localStorage.getItem(USER_KEY));
  }

  public getPermisos(){
    const user = this.getUser();
    return user.permisos;
  }

  public isPermiso(permiso: string){
    let isLoggedIn = !!this.getToken();
    if (isLoggedIn) {
      let permisos = this.getPermisos();
      return permisos.includes(permiso);
    }
    return false;
  }
}
