import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-volver-button',
  templateUrl: './volver-button.component.html',
  styleUrls: ['./volver-button.component.scss']
})
export class VolverButtonComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }
  volverAlInicio(){
    this.router.navigate(['/']);
  }

}
