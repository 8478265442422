import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  Event,
  NavigationStart,
  NavigationError
} from "@angular/router";
import * as $ from "jquery";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { NotificacionesService } from './services/alertas/notificaciones.service';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { PedidosService } from './services/pedidos/pedidos.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @ViewChild('templateBottomSheet') TemplateBottomSheet: TemplateRef<any>;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  durationInSeconds = 5;
  title = 'Flex Paraguay';
  datosuser: any = { "roles": [] };
  ocultar = true;
  visibleMenu = false;
  sugerencias: any = [];
  cliente: any = { "es_cliente": true };
  administrador_view = ["pedidos", "administracion", "clientes", "platos","reportes"]
  clientes_view = ["pedidos", "adicionales", "empleados","reportes"]
  len: any = 0;
  nombre: any = "";
  constructor(private pedidoService: PedidosService, private bottomSheet: MatBottomSheet, private notificationService: NotificacionesService, private _snackBar: MatSnackBar, public router: Router, private activatedRoute: ActivatedRoute) {

    this.notificationService.success.subscribe(message => {
      this._snackBar.open(message, 'Cerrar', {
        duration: this.durationInSeconds * 1000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        panelClass: ["toast-class"]
      });
    });
    this.notificationService.error.subscribe(message => {
      this._snackBar.open(message, 'Cerrar', {
        duration: this.durationInSeconds * 1000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        panelClass: ["toast-class-error"]
      });
    });
    this.notificationService.info.subscribe(message => {
      this._snackBar.open(message, 'Cerrar', {
        duration: this.durationInSeconds * 1000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        panelClass: ["toast-class-info"]
      });
    });
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.getSugerencias();
        if (router.url.includes("administracion") && localStorage.getItem("auth-userFlex")) {
          this.datosuser = JSON.parse(localStorage.getItem("auth-userFlex"));
          this.cliente = JSON.parse(localStorage.getItem("auth-attr"));
          this.ocultar = false;
          this.checkPermisos(this.cliente, router.url);
          $("#mat-toolbar").removeClass("hideNoAuth");
        this.nombre = this.datosuser[0].first_name + " " + this.datosuser[0].last_name;

        } else if (router.url.includes("administracion")) {
          this.ocultar = true;
          $("#mat-toolbar").addClass("hideNoAuth");
          this.router.navigate(['/administracion/login']);
        } else {
          this.ocultar = true;
          $("#mat-toolbar").addClass("hideNoAuth");
        }
        this.setHeaderMenus();
      }
    });
  }

  checkPermisos(cliente, url) {
    console.log(cliente)
    if (cliente.es_cliente) {
      if (url.includes("/")) {
        if (url.split("/").length > 2) {
          var namePage = url.split("/")[2];
          if (!this.clientes_view.includes(namePage)) {
            this.router.navigate(['/administracion/pedidos']);
          }
        } else {
          this.router.navigate(['/administracion/pedidos']);

        }
      }

    }
  }
  getSugerencias() {
    this.pedidoService.listarSugerencias().subscribe(
      data => {
        if (data.estado == 0) {
          this.sugerencias = data.data;
          this.len = this.sugerencias.length;
        } else {
          this.notificationService.error.next(data.mensaje);
        }
      },
      err => {
      }
    );
  }
  ngOnInit() {
    this.setHeaderMenus();
  }
  setHeaderMenus() {
    var router = this.router;
    if (router.url.includes("/")) {
      if (router.url.split("/").length > 2) {
        var namePage = router.url.split("/")[2]
        $("#menu>a.active").removeClass("active");
        if (router.url.includes(namePage)) {
          $("#" + namePage).addClass("active")
        }
      } else {
        $("#menu>a.active").removeClass("active");
        $("#administracion").addClass("active")
      }
    }
  }
  sucess() {
    this._snackBar.open('¡Operación exitosa!', 'Cerrar', {
      duration: this.durationInSeconds * 1000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: ["toast-class"]
    });
  }

  logout() {
    localStorage.removeItem("auth-userFlex");
    localStorage.removeItem("auth-tokenFlex");
    localStorage.removeItem("auth-attr");
    this.router.navigate(['/administracion/login']);

  }
  openTemplateSheetMenu() {
    this.bottomSheet.open(this.TemplateBottomSheet);
  }

  closeTemplateSheetMenu() {
    this.bottomSheet.dismiss();
  }
}
