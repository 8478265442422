import {environment} from '../../environments/environment';

const url = '/api/';

const Servers = {
  FLEX: {
    baseUrl: `${url}`
  }
};

export { Servers };
